export default {
  collection_id: state => state.collection_id,
  collection_name: state => state.collection_name,
  collection: state => {
    state.collection.map((item) => {
      let parts = ''
      // switch ---------------------------------------------------------------
      item.artikulSmatrCatalog = []
      if (item.type === 'switch') {
        if (item.parameters.series === 'W85') {
          parts += item.artikul.split("-").slice(0, 2).join("-")
          parts += '-' + item.artikul.split("-")[3]
          item.artikulSmatrCatalog = parts
        } else {
          parts += item.artikul.split("-").slice(0, 2).join("-")
          item.artikulSmatrCatalog.push(parts)
        }
      } else {
        // combo and frames -------------------------------------------------
        let filteredSlots = { ...item.parameters.selectedSlots }
        // соединяем R1
        let posts = 1;
        let count = 0;
        for (let key in item.parameters.selectedSlots) {
          if (key <= item.parameters.slotsCount && item.parameters.selectedSlots[key]) {
            if (item.parameters.selectedSlots[key] === 'R1') {
              count++;
            }
          }
        }
        if (item.parameters.slotsCount == count) {
          posts = item.parameters.slotsCount
          for (let key in item.parameters.selectedSlots) {
            if (key <= item.parameters.slotsCount && item.parameters.selectedSlots[key]) {
              filteredSlots[key] = null
            }
          }
          filteredSlots[1] = 'R' + count
        }
        // соединяем F1
        count = 0;
        for (let key in item.parameters.selectedSlots) {
          if (key <= item.parameters.slotsCount && item.parameters.selectedSlots[key]) {
            if (item.parameters.selectedSlots[key] === 'F1') {
              count++;
            }
          }
        }
        if (item.parameters.slotsCount == count) {
          posts = item.parameters.slotsCount
          for (let key in item.parameters.selectedSlots) {
            if (key <= item.parameters.slotsCount && item.parameters.selectedSlots[key]) {
              filteredSlots[key] = null
            }
          }
          filteredSlots[1] = 'F' + count
        }
        // соединяем FB1
        count = 0;
        for (let key in item.parameters.selectedSlots) {
          if (key <= item.parameters.slotsCount && item.parameters.selectedSlots[key]) {
            if (item.parameters.selectedSlots[key] === 'FB1') {
              count++;
            }
          }
        }
        if (item.parameters.slotsCount == count) {
          posts = item.parameters.slotsCount
          for (let key in item.parameters.selectedSlots) {
            if (key <= item.parameters.slotsCount && item.parameters.selectedSlots[key]) {
              filteredSlots[key] = null
            }
          }
          filteredSlots[1] = 'FB' + count
        }
        // соединяем U1
        count = 0;
        for (let key in item.parameters.selectedSlots) {
          if (key <= item.parameters.slotsCount && item.parameters.selectedSlots[key]) {
            if (item.parameters.selectedSlots[key] === 'U1') {
              count++;
            }
          }
        }
        if (item.parameters.slotsCount == count) {
          posts = item.parameters.slotsCount
          for (let key in item.parameters.selectedSlots) {
            if (key <= item.parameters.slotsCount && item.parameters.selectedSlots[key]) {
              filteredSlots[key] = null
            }
          }
          filteredSlots[1] = 'U' + count
        }
        // соединяем I1
        count = 0;
        for (let key in item.parameters.selectedSlots) {
          if (key <= item.parameters.slotsCount && item.parameters.selectedSlots[key]) {
            if (item.parameters.selectedSlots[key] === 'I1') {
              count++;
            }
          }
        }
        if (item.parameters.slotsCount == count) {
          posts = item.parameters.slotsCount
          for (let key in item.parameters.selectedSlots) {
            if (key <= item.parameters.slotsCount && item.parameters.selectedSlots[key]) {
              filteredSlots[key] = null
            }
          }
          filteredSlots[1] = 'I' + count
        }


        for (let key in filteredSlots) {
          if (key <= item.parameters.slotsCount && filteredSlots[key]) {
            parts = ''
            // это выключатель
            let button = false
            // let num1 = item.parameters.selectedSlots[key][item.parameters.selectedSlots[key].length - 2]
            // let num2 = item.parameters.selectedSlots[key][item.parameters.selectedSlots[key].length - 1]
            let num1 = filteredSlots[key][0]
            let num2 = filteredSlots[key][1]
            if (
                (!isNaN(num1) && !isNaN(num2)) ||
                (num1 === 'B' && !isNaN(num2))
            ) {
              button = true
            } else {
              parts += posts + 'H'
            }

            // console.log(num1)
            // console.log(num2)
            // console.log(button)

            // исключение для S
            if (item.parameters.series == 85 || item.parameters.series == 90) {
              parts += 'S' + item.parameters.series
            } else {
              parts += item.parameters.series
            }

            parts += '-' + filteredSlots[key]

            // добавляем сколько кнопок отсутствует в Active
            if (item.parameters.series === 'A85' || item.parameters.series === 'A90') {
              if (button) {
                parts += num2 - item.parameters.noButtonsCount
              }
            }

            // добавляем тип дерева для wood
            if (item.parameters.series === 'W85') {
              parts += '-' + item.artikul.split("-")[3]
            }

            item.artikulSmatrCatalog.push(parts)
          }
        }


        // let targetValue = "R1";
        // let count = 0;
        // for (let key in item.parameters.selectedSlots) {
        //   if (item.parameters.selectedSlots[key] === targetValue) {
        //     count++;
        //   }
        // }
        // parts += ' '+count

      }
      // else if (item.type === 'socket') {
      //   console.log(343)
      // }


      return item
    })
    return state.collection
  },
  messageSaveCollection: state => state.messageSaveCollection,
  collection_names: state => state.collection_names,
  collections_history: state => state.collections_history,
  collection_ordered: state => state.collection_ordered,
}